<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <md-content class="md-scrollbar">
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="radio-group">
                                <label>Loại thiết lập</label>
                                <div class="form-control">
                                    <md-radio v-model="entity.isCommon" :value="true">Phí chung</md-radio>
                                    <md-radio v-model="entity.isCommon" :value="false">Phí con't/kiện hàng</md-radio>
                                </div>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="branchName" @md-selected="getBranchSelected" :md-options="branchs" @md-changed="getBranchs"  @md-opened="getBranchs" :class="{'md-invalid': submitted && $v.entity.branchId.$error }">
                                    <label>Chi nhánh</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.branchName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.branchId.isSelected">Vui lòng chọn chi nhánh</span>
                                </md-autocomplete>
                                <md-button @click="openBranch()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Khách hàng<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-6">
                            <div class="form-control">
                                <md-autocomplete v-model="feeName" @md-selected="getFeeSelected" :md-options="fees" @md-changed="getFees" @md-opened="getFees" :class="{'md-invalid': submitted && $v.entity.feeId.$error }">
                                    <label>Tên phí</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.feeName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.feeId.isSelected">Vui lòng chọn loại phí</span>
                                </md-autocomplete>
                                <md-button @click="openFee()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.type.$error }">
                                <label for="type">Loại phí</label>
                                <md-select v-model="entity.type" name="type" id="type">
                                    <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                </md-select>
                                <span class="md-error" v-if="submitted && !$v.entity.type.isSelected">Vui lòng chọn loại phí</span>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.amount.$error }">
                                <label for="amount">Thành tiền</label>
                                <number v-model="entity.amount" v-bind="currencyF" class="form-control currency"></number>
                                <span class="md-error" v-if="submitted && !$v.entity.amount.required">Vui lòng nhập số tiền</span>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-field>
                                    <label>Loại hình</label>
                                    <md-select v-model="entity.typeOfTransport">
                                        <md-option :value="0">Chọn loại hình...</md-option>
                                        <md-option v-for="item in typeList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-field>
                                    <label>Loại hàng</label>
                                    <md-select v-model="entity.goodsTypeId">
                                        <md-option :value="0">Chọn loại hàng...</md-option>
                                        <md-option v-for="item in goodsTypeList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="unitName" @md-selected="getUnitSelected" :md-options="units" @md-changed="getUnits"  @md-opened="getUnits">
                                    <label>Đơn vị</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.unitName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openUnit()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="goodsName" @md-selected="getGoodsSelected" :md-options="goods" @md-changed="getGoods"  @md-opened="getGoods">
                                    <label>Hàng hóa</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.goodsName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm hàng hóa</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="sectorReceiptGoodsName" @md-selected="getSectorReceiptGoodsSelected" :md-options="listSectorReceiptGoods" @md-changed="getSectorReceiptGoodsList"  @md-opened="getSectorReceiptGoodsList">
                                    <label>Khu vực nơi lấy cont/đóng hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openSectorReceiptGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="sectorDeliveryGoodsName" @md-selected="getSectorDeliveryGoodsSelected" :md-options="listSectorDeliveryGoods" @md-changed="getSectorDeliveryGoodsList"  @md-opened="getSectorDeliveryGoodsList">
                                    <label>Khu vực nơi trả cont/trả hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openSectorDeliveryGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
        <goodsList ref="goodsList" @close="closeGoods"/>
        <feeList ref="feeList" title="Phí làm hàng" @close="closeFee"/>
        <unitList ref="unitList" @close="closeUnit"/>
        <branchList ref="branchList" @close="closeBranch"/>
        <sectorReceiptGoodsList ref="sectorReceiptGoodsList" @close="closeSectorReceiptGoods"/>
        <sectorDeliveryGoodsList ref="sectorDeliveryGoodsList" @close="closeSectorDeliveryGoods"/>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
    </md-dialog>
</template>

<script>
    import goodsService from '../../../api/goodsService';
    import sectorService from '../../../api/sectorService';
    import branchService from '../../../api/branchService';
    import settingFeeService from '../../../api/settingFeeService';
    import companyService from '../../../api/companyService';
    import unitService from '../../../api/unitService';
    import feeService from '../../../api/feeService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import feeList from '../../../components/popup/_FeeList.vue';
    import unitList from '../../../components/popup/_UnitList.vue';
    import branchList from '../../../components/popup/_BranchList.vue';
    import goodsList from '../../../components/popup/_GoodsList.vue';
    import sectorReceiptGoodsList from '../../../components/popup/_SectorList.vue';
    import sectorDeliveryGoodsList from '../../../components/popup/_SectorList.vue';
    import customerList from '../../../components/popup/_CompanyList.vue';

    export default ({
        components: {
            goodsList,
            feeList,
            unitList,
            branchList,
            sectorReceiptGoodsList,
            sectorDeliveryGoodsList,
            customerList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật thiết lập phí làm hàng'
        },
        data() {
            return {
                showDialog: false,
                title: '',
                goodsTypeList: common.goodsTypeList,
                typeList: common.typeOfTransport,
                types: common.typeFee,
                id: 0,
                submitted: false,
                entity: { id: 0, branchId: 0, feeId: 0, isCommon: true, goodsId: null, sectorReceiptGoodsId: null, sectorDeliveryGoodsId: null, typeOfTransport: null, goodsTypeId: null, type: 1, unitId: null, amount: 0 },
                saveAndCreate: false,
                feeName: '',
                fees: [],
                unitName: '',
                units: [],
                currencyF: common.currencyF2,
                branchName: '',
                branchs: [],
                goodsName: '',
                goods: [],
                sectorReceiptGoodsName: '',
                listSectorReceiptGoods: [],
                sectorDeliveryGoodsName: '',
                listSectorDeliveryGoods: [],
                customerName: '',
                customers: [],
                customerType: common.companyType.customer,
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async open(id){
                this.id = id;
                this.branchName = '';
                this.unitName = '';
                this.feeName = '';
                this.customerName = '';
                if(id == 0){
                    this.title = 'Thêm mới thiết lập phí làm hàng';
                    this.entity = { id: 0, isCommon: true, branchId: 0, feeId: 0, customerId: null, goodsId: null, sectorReceiptGoodsId: null, sectorDeliveryGoodsId: null, typeOfTransport: null, goodsTypeId: null, type: 1, unitId: null, amount: 0  };
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật thiết lập phí làm hàng';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.entity.customerId = item.id;
                this.entity.customer = item;
                this.$refs.customerList.close();
                this.entity.contractId = 0;
                this.contractName = '';
            },

            getCustomerSelected(val){
                this.entity.customerId = val.id;
                this.customerName = val.companyAlias;
                this.entity.customer = val;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.customer, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Goods
            closeGoods(item){
                this.goodsName = item.goodsName;
                this.entity.goods = item;
                this.entity.goodsId = item.id;
                this.$refs.goodsList.close();
            },

            getGoodsSelected(val){
                this.entity.goods = val;
                this.entity.goodsId = val.id;
                this.goodsName = val.goodsName;
            },

            openGoods(){
                this.$refs.goodsList.open();
            },

            getGoods(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 1, code:  searchTerm };
                goodsService.getGoodss(search).then((response) => {
                    if(response.statusCode == 200){
                        this.goods = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //SectorReceiptGoods
            closeSectorReceiptGoods(item){
                this.sectorReceiptGoodsName = item.sectorName;
                this.entity.sectorReceiptGoodsId = item.id;
                this.entity.sectorReceiptGoods = item;
                this.$refs.sectorReceiptGoodsList.close();
            },

            openSectorReceiptGoods(){
                this.$refs.sectorReceiptGoodsList.open(0);
            },

            getSectorReceiptGoodsSelected(val){
                this.entity.sectorReceiptGoodsId = val.id;
                this.entity.sectorReceiptGoods = val;
                this.sectorReceiptGoodsName = val.sectorName;
            },
            
            getSectorReceiptGoodsList(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                sectorService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.listSectorReceiptGoods = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //SectorDeliveryGoods
            closeSectorDeliveryGoods(item){
                this.sectorDeliveryGoodsName = item.sectorName;
                this.entity.sectorDeliveryGoodsId = item.id;
                this.entity.sectorDeliveryGoods = item;
                this.$refs.sectorDeliveryGoodsList.close();
            },

            openSectorDeliveryGoods(){
                this.$refs.sectorDeliveryGoodsList.open(0);
            },

            getSectorDeliveryGoodsSelected(val){
                this.entity.sectorDeliveryGoodsId = val.id;
                this.entity.sectorDeliveryGoods = val;
                this.sectorDeliveryGoodsName = val.sectorName;
            },
            
            getSectorDeliveryGoodsList(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                sectorService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.listSectorDeliveryGoods = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Branch
            closeBranch(item){
                this.branchName = item.branchName;
                this.entity.branch = item;
                this.entity.branchId = item.id;
                this.$refs.branchList.close();
            },

            openBranch(){
                this.$refs.branchList.open();
            },

            getBranchSelected(val){
                this.entity.branchId = val.id;
                this.branchName = val.branchName;
                this.entity.branch = val;
            },
            
            getBranchs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                branchService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Unit
            closeUnit(item){
                this.unitName = item.unitName;
                this.entity.unit = item;
                this.entity.unitId = item.id;
                this.$refs.unitList.close();
            },

            openUnit(){
                this.$refs.unitList.open();
            },

            getUnitSelected(val){
                this.entity.unitId = val.id;
                this.unitName = val.unitName;
                this.entity.unit = val;
            },
            
            getUnits(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                unitService.getUnits(search).then((response) => {
                    if(response.statusCode == 200){
                        this.units = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            close(){
                this.showDialog = false;
            },

            //Fee
            closeFee(item){
                this.feeName = item.feeName;
                this.entity.feeId = item.id;
                this.$refs.feeList.close();
            },

            getFeeSelected(val){
                this.entity.feeId = val.id;
                this.feeName = val.feeName;
            },

            openFee(){
                this.$refs.feeList.open(1);
            },

            getFees(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 1, code: searchTerm };
                feeService.getFees(search).then((response) => {
                    if(response.statusCode == 200){
                        this.fees = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.typeOfTransport == 0){
                    this.entity.typeOfTransport = null;
                }
                if(this.entity.goodsTypeId == 0){
                    this.entity.goodsTypeId = null;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                settingFeeService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('close', this.saveAndCreate);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                settingFeeService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('close', this.saveAndCreate);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                settingFeeService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.branchName = response.data.branch.branchName;
                        this.feeName = response.data.fee.feeName;
                        this.unitName = "";
                        if(response.data.unitId > 0){
                            this.unitName = response.data.unit.unitName;
                        }
                        if(response.data.customerId > 0){
                            this.customerName = response.data.customer.companyAlias;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/fee');
            }
        },
        watch: { 
            feeName: function (val){
                if(val == ''){
                    this.entity.feeId = 0;
                }
            },
            unitName: function (val) { 
                if(val == ''){
                    this.entity.unitId = null;
                }
            },
            branchName: function (val) { 
                if(val == ''){
                    this.entity.branchId = 0;
                }
            },
            goodsName: function (val) { 
                if(val == ''){
                    this.entity.goodsId = null;
                }
            },
            customerName: function (val) { 
                if(val == ''){
                    this.entity.customerId = null;
                }
            },
        },
        validations: {
            entity: {
                branchId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                feeId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                type: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                amount: { required }
            }
       }
     })

</script>


<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 900px;
        height: 450px;
        max-width: 900px;
    }
    .md-scrollbar {
        height: 460px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>